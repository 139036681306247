import "./StudentsLessonPlanLayOut.css";
import smallLogo from "../../img/logo-small.svg";
import logo from "../../img/GItserund_web_logo.svg";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getLessonPlanForStudent,
  pendingLessonPlanForStudent,
} from "../../store/student/actions/getLessonPlanForStudent";
import { useNavigate, useParams } from "react-router";

export function StudentsLessonPlanLayOut({ children }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const lessonPlanData = useSelector(
    (state) => state.studentsReducer.lessonPlanData
  );

  const currentUrl = window.location.href;

  const { id } = useParams();
  const keyIndex = currentUrl.indexOf("key=") + 4;
  const key = currentUrl.substring(keyIndex);

  useEffect(() => {
    dispatch(getLessonPlanForStudent(id, key));
    pendingLessonPlanForStudent(true);
  }, []);

  return (
    <>
      {window.innerWidth <= 1120 ? (
        <div className="students-lesson-plan">
          <div className="lesson-plan-header-responsive">
            <button
              onClick={() => navigate("/home")}
              className="logo-button-for-mobile"
            >
              <img src={logo} alt="logo" className="stm-logo" />
            </button>
          </div>
          <div className="lesson-plan-content-responsive">{children}</div>
        </div>
      ) : (
        <div className="students-lesson-plan">
          <div className="lesson-plan-header">
            <button onClick={() => navigate("/home")} className="logo-button">
              <img src={smallLogo} alt="logo" className="stm-logo" />
            </button>
            <p className="lesson-plan-title">
              {lessonPlanData?.lessonPlanTopic}
            </p>
          </div>
          <div className="lesson-plan-content">{children}</div>
        </div>
      )}
    </>
  );
}
