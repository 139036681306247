import axios from "axios";
import {
  GET_LESSON_PLAN_LINK_FAILURE,
  GET_LESSON_PLAN_LINK_REQUEST,
  GET_LESSON_PLAN_LINK_SUCCESS,
} from "../types";
import { handleFormChange } from "../../form/actions";
import { detectBrowser } from "../../../helpers/detectBrowser";

export const getLessonPlanLink = (id, handleOpenPopup) => {

  return (dispatch) => {
    axios
      .get(`/api/lessonPlan/link?id=${id}`)
      .then((res) => {
        if (res.data.success) {

          dispatch(successGetLessonPlanLink(res.data.data));
          
          const isSafaryBrowser = detectBrowser() === 'Safari';

          if(!isSafaryBrowser) {
            navigator.clipboard.writeText(res.data.data.link);
          };

          dispatch(handleFormChange('studentLinkForShare', res.data.data.link));
          
          handleOpenPopup();
        };
        return res.data.data;
      })
      .catch((error) => {
        dispatch(errorGetLessonPlanLink(error.message));
        throw error.message;
      })
      .finally(() => {
        dispatch(pendingLessonPlanLink(false));
      });
  };
};

export const pendingLessonPlanLink = (isLoadingLink) => {
  return {
    type: GET_LESSON_PLAN_LINK_REQUEST,
    payload: {
      isLoadingLink,
    },
  };
};

const successGetLessonPlanLink = (lessonPlanLinkForStudent) => {
  return (dispatch) => {
    
    dispatch({
      type: GET_LESSON_PLAN_LINK_SUCCESS,
      payload: {
        lessonPlanLinkForStudent,
      },
    });
  };
};

const errorGetLessonPlanLink = (error) => {
  return {
    type: GET_LESSON_PLAN_LINK_FAILURE,
    payload: {
      error,
    },
  };
};
