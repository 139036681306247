import React, { useEffect, useState } from "react";
import "./tabs.css";
import { useNavigate } from "react-router";

export function Tab(props) {
  return <div>{props.children}</div>;
}

export function Tabs(props) {
  const {
    onChangeTab = () => {},
    activeIndex = 0,
    children = [],
    tabs = [],
  } = props;
  const [currentTab, setCurrentTab] = useState(activeIndex);
  const navigate = useNavigate();

  const currentUrl = new URL(window.location.href);
  const { pathname, searchParams } = currentUrl;

  const handleClick = (index) => {
    setCurrentTab(index);
    onChangeTab(index);

    searchParams.set("page", "1");
    const modifiedUrl = `${pathname}?${searchParams.toString()}`;
    navigate(modifiedUrl);
  };

  return (
    <div className="tabs-container">
      <div className="tab-container">
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={index === currentTab ? "tab tab-selected" : "tab"}
            onClick={() => handleClick(index)}
          >
            <p
              className={
                index === currentTab ? "tab-selected-title" : "tab-title"
              }
            >
              {tab}
            </p>
            {index === currentTab && (
              <div className="underline-tab-container" />
            )}
          </div>
        ))}
      </div>
      <div className="tab-content">{children[currentTab]}</div>
    </div>
  );
}
