import React from "react";

export function UsersIcon({className}) {
    return (
        <svg
            className={className}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                fill="#A8B0B9"
                d="M6.75 3C4.69114 3 3 4.69114
                3 6.75V17.25C3 19.3089 4.69114 21 6.75
                 21H17.25C19.3089 21 21 19.3089
                  21 17.25V6.75C21 4.69114 19.3089
                  3 17.25 3H6.75ZM6.75 5H17.25C18.2251
                   5 19 5.77486 19 6.75V17.25C19 18.2251 18.2251
                    19 17.25 19H6.75C5.77486 19 5 18.2251 5 17.25V6.75C5
                    5.77486 5.77486 5 6.75 5ZM8 6.61914C7.88775 6.61914
                     7.77492 6.6835 7.73242 6.8125C7.53742 7.4065 7.07152 7.87141
                      6.47852 8.06641C6.22052 8.15141 6.22052 8.51461 6.47852
                      8.59961C7.07152 8.79561 7.53742 9.26147 7.73242
                       9.85547C7.81742 10.1135 8.18258 10.1135 8.26758
                       9.85547C8.46258 9.26147 8.92848 8.79561 9.52148
                       8.59961C9.77948 8.51461 9.77948 8.15141 9.52148
                        8.06641C8.92848 7.87141 8.46258 7.4065 8.26758
                         6.8125C8.22508 6.6835 8.11225 6.61914 8 6.61914ZM14
                         7.40039C13.8667 7.40039 13.7336 7.47591 13.6836
                         7.62891L13.2148 9.05859C12.9828 9.76259 12.4306 10.3149
                         11.7266 10.5469L10.2969 11.0176C9.99088 11.1176 9.99088
                         11.5504 10.2969 11.6504L11.7266 12.1191C12.4306 12.3511
                          12.9828 12.9034 13.2148 13.6074L13.6855 15.0371C13.7865
                          15.3431 14.2184 15.3431 14.3184 15.0371L14.7871 13.6074C15.0181
                           12.9034 15.5714 12.3511 16.2754 12.1191L17.7051 11.6504C18.0111
                           11.5504 18.0111 11.1176 17.7051 11.0176L16.2754 10.5469C15.5714
                            10.3159 15.0191 9.76259 14.7871 9.05859L14.3164 7.62891C14.2664
                             7.47591 14.1333 7.40039 14 7.40039ZM9.33398 13.4004C9.20073
                             13.4004 9.06758 13.4759 9.01758 13.6289L8.95898 13.8047C8.72698
                              14.5087 8.1747 15.061 7.4707 15.293L7.29688 15.3496C6.99088
                              15.4496 6.99088 15.8824 7.29688 15.9824L7.4707 16.041C8.1747
                               16.273 8.72698 16.8253 8.95898 17.5293L9.01758 17.7031C9.11858
                                18.0091 9.55039 18.0091 9.65039 17.7031L9.70703
                                 17.5293C9.93903 16.8253 10.4913 16.273 11.1953
                                  16.041L11.3711 15.9824C11.6771 15.8824 11.6771
                                   15.4496 11.3711 15.3496L11.1953 15.293C10.4913
                                    15.061 9.93903 14.5087 9.70703 13.8047L9.65039
                                    13.6289C9.60039 13.4759 9.46723 13.4004 9.33398
                                    13.4004Z"
            />
        </svg>
    )
}
