import axios from "axios";
import {
  GET_RATING_DATA_FAILURE,
  GET_RATING_DATA_REQUEST,
  GET_RATING_DATA_SUCCESS,
} from "../types";

export const getRatingData = (
  page,
  count,
  orderColumn,
  isOrderAsc,
  searchText,
  classs,
  subjectId
) => {
  return (dispatch) => {
    let queryParams = `/api/lessonPlan/withRate?page=${page}&count=${count}&orderColumn=${orderColumn}&isOrderAsc=${isOrderAsc}`;

    if (classs) {
      queryParams += `&classs=${classs}`;
    }

    if (subjectId) {
      queryParams += `&subjectId=${subjectId}`;
    }

    if (searchText) {
      queryParams += `&searchText=${searchText}`;
    }
    axios
      .get(queryParams)
      .then((data) => {
        dispatch(successGetRatingData(data.data.data));
        return data;
      })
      .catch((error) => {
        dispatch(errorGetRatingData(error.message));
        console.log(error.message);
        throw error.message;
      })
      .finally(() => {
        dispatch(pendingGetRatingData(false));
      });
  };
};

export const pendingGetRatingData = (isLoadingRatingData) => {
  return {
    type: GET_RATING_DATA_REQUEST,
    payload: {
      isLoadingRatingData,
    },
  };
};

const successGetRatingData = (ratingData) => {
  return async (dispatch) => {
    try {
    } catch (error) {
      console.error(error.message);
    }
    dispatch({
      type: GET_RATING_DATA_SUCCESS,
      payload: {
        ratingData,
      },
    });
  };
};

const errorGetRatingData = (error) => {
  return {
    type: GET_RATING_DATA_FAILURE,
    payload: {
      error,
    },
  };
};
