import "../studentsLessonPlan.css";
import timer from "../../../img/timer.svg";
import empty from "../../../img/empty-board.png";
import { useCallback, useEffect, useMemo, useState } from "react";
import { MethodDetails } from "./MethodDetails";
import blackCrow from "../../../img/blackCrow.svg";

export const Board = ({ lessonPlanData }) => {
  const [openDetail, setOpenDetail] = useState(false);
  const [currentMethod, setCurrentMethod] = useState();
  const [detailImagesourceFirst, setDetailImagesourceFirst] = useState("");
  const [detailImageSourceSec, setDetailImageSourceSec] = useState("");
  const lessonPlanViewData = lessonPlanData?.process;
  const steps = 5;
  const allActions = useMemo(() => {
    let newActions = [];

     for (let i = 0; i < lessonPlanViewData?.length; i++) {
      newActions.push(...lessonPlanViewData[i].actions);
    }
    return newActions;
  }, [lessonPlanViewData])

  const handleOpenDetail = (lessonPlan) => {
    setCurrentMethod(lessonPlan);
    setOpenDetail(true);
  };

  const handleCloseDetail = useCallback(() => {
    setOpenDetail(false);
  }, []);


  let actionNumber = 0;

  return (
    <>
      {lessonPlanViewData?.map((process) => {
        return process?.actions?.map((action, index) => {
          actionNumber++
          return (
            <div className="board-container" key={index}>
              <div className="borard-header">
                <div className="alert-button">
                  Քայլ {actionNumber}/{allActions.length}
                </div>
                {action.duration > 1 && (
                  <div className="timer-content">
                    <img src={timer} className="timer-img" alt="timer" />
                    {action.duration} ր
                  </div>
                )}
              </div>
              <div className="board-desc">
                <div className="board-header">
                  {action?.files?.procImages?.files?.length > 0 && (
                    <p className="board-title">Գրատախտակ</p>
                  )}

                  {action?.studentAction && (
                    <div
                      className={
                          "sections-student-description studentActionBoard"
                      }
                      style={{ marginTop: 16 }}
                    >
                      <div style={{ display: "flex", width: "100%" }}>
                        <div className="imageDiv">
                          <img src={blackCrow} alt="board" />
                        </div>

                        <div className={"clone-image-div"} />
                        <p className="balckboard">{action.studentAction}</p>
                      </div>
                    </div>
                  )}
                </div>

                <div className="board-content">
                  <div>
                    <div className="board-images">
                      {action?.files?.procImages?.files?.length > 0 ? (
                        action.files.procImages.files.map((lp, index) => (
                          <>
                            <img
                              onClick={() => {
                                handleOpenDetail(action);
                                setDetailImagesourceFirst(lp);
                                setDetailImageSourceSec(
                                  action.files.procImages.urlPath
                                );
                              }}
                              className="board-image"
                              key={index}
                              src={`${action.files.procImages.urlPath}/${lp}`}
                              alt={lp}
                            />
                          </>
                        ))
                      ) : (
                        <img src={empty} />
                      )}
                    </div>
                  </div>

                  <div className="righ-part">
                    {action?.studentActionDesc && (
                      <p
                        className="board-text student-text"
                        dangerouslySetInnerHTML={{
                          __html: action?.studentActionDesc,
                        }}
                      />
                    )}
                    <hr className="board-line" />

                    <div
                      className="student-desc"
                      dangerouslySetInnerHTML={{
                        __html: action?.pupilWorkPart,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        });
      })}
      {openDetail && (
        <MethodDetails
          currentMethod={currentMethod}
          steps={steps}
          handleCloseDetail={handleCloseDetail}
          detailImagesourceFirst={detailImagesourceFirst}
          detailImageSourceSec={detailImageSourceSec}
          openDetail={openDetail}
        />
      )}
    </>
  );
};
