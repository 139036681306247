import { StudentsLessonPlanLayOut } from "../../layouts/StudentsLessonPlanLayOut/StudentsLessonPlanLayOut";
import "./studentsLessonPlan.css";
import { Board } from "./components/Board";
import { Rating } from "./components/Rating";
import { ProgressBar } from "./components/ResponsiveBoard";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useEffect } from "react";
import {
  getLessonPlanForStudent,
  pendingLessonPlanForStudent,
} from "../../store/student/actions/getLessonPlanForStudent";
import { Loading } from "../../components";

export const StudentsLessonPlan = () => {
  const dispatch = useDispatch();

  const lessonPlanData = useSelector(
    (state) => state.studentsReducer.lessonPlanData
  );

  const isLoadingLessonPlanData = useSelector(
    (state) => state.studentsReducer.isLoadingLessonPlanData
  );

  const currentUrl = window.location.href;

  const { id } = useParams();
  const keyIndex = currentUrl.indexOf("key=") + 4;
  const key = currentUrl.substring(keyIndex);

  useEffect(() => {
    dispatch(getLessonPlanForStudent(id, key));
    pendingLessonPlanForStudent(true);
  }, []);

  return (
    <>
      {isLoadingLessonPlanData && <Loading />}
      <StudentsLessonPlanLayOut>
        {window.innerWidth <= 1120 ? (
          <>
            <ProgressBar lessonPlanData={lessonPlanData} />
          </>
        ) : (
          <>
            <Board lessonPlanData={lessonPlanData} />
            <Rating lessonPlanData={lessonPlanData} />
          </>
        )}
      </StudentsLessonPlanLayOut>
    </>
  );
};
