import axios from "axios";
import {
  GET_LESSON_PLAN_STUDENT_FAILURE,
  GET_LESSON_PLAN_STUDENT_REQUEST,
  GET_LESSON_PLAN_STUDENT_SUCCESS,
} from "../types";

export const getLessonPlanForStudent = (id, key) => {
  return (dispatch) => {
    axios
      .get(`/api/lessonPlan/lessonPlanDetails/pupilView?id=${id}&key=${key}`)
      .then((data) => {
        dispatch(successGetLessonPlanForStudent(data.data));
        return data;
      })
      .catch((error) => {
        dispatch(errorGetLessonPlanForStudent(error.message));
        console.log(error.message);
        throw error.message;
      })
      .finally(() => {
        dispatch(pendingLessonPlanForStudent(false));
      });
  };
};

export const pendingLessonPlanForStudent = (isLoadingLessonPlanData) => {
  return {
    type: GET_LESSON_PLAN_STUDENT_REQUEST,
    payload: {
      isLoadingLessonPlanData,
    },
  };
};

const successGetLessonPlanForStudent = (lessonPlanData) => {
  return async (dispatch) => {
    try {
    } catch (error) {
      console.error(error.message);
    }
    dispatch({
      type: GET_LESSON_PLAN_STUDENT_SUCCESS,
      payload: {
        lessonPlanData,
      },
    });
  };
};

const errorGetLessonPlanForStudent = (error) => {
  return {
    type: GET_LESSON_PLAN_STUDENT_FAILURE,
    payload: {
      error,
    },
  };
};
