import { useCallback, useEffect, useState } from "react";
import { getRatingData } from "../../../../store/student/actions/getRatingData";
import { TableContent } from "./components/TableContent";
import triangleIcon from "../../../../img/top-down-arrows.svg";
import Pagination from "../../../../components/Pagination";
import { useQuery } from "../../../../hooks/useQuery";
import "./tableComponent.css";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

const TableComponent = ({
  searchRate,
  openFilter,
  ratingData,
  ratingError,
  ratingSearch,
  ratingClass,
  ratingSubject,
  getRatingData,
}) => {
  const query = useQuery();
  const { t } = useTranslation();

  const [column, setColumn] = useState("");
  const [isOrderAsc, setIsOrderAsc] = useState(false);

  const rating = ratingData?.[0];

  const count = rating?.[0]?.lessonPlansCount;
  const search = openFilter ? ratingSearch : searchRate;

  const onColumnHeaderClick = useCallback(
    (text) => {
      setColumn(text);
      setIsOrderAsc((prevIsOrderAsc) => !prevIsOrderAsc);
    },
    [column, isOrderAsc]
  );

  let page = +query.get("page") ?? 1;
  let pageCount = Math?.ceil(+count / 10);

  useEffect(() => {
    getRatingData(
      page,
      10,
      column,
      isOrderAsc,
      search,
      ratingClass,
      ratingSubject
    );
  }, [column, isOrderAsc, page, search, ratingClass, ratingSubject]);

  return (
    <div>
      <div className="rating tableContainer">
        {ratingData[0] && ratingData[0].length > 0 && (
          <table>
            <tr className="thead">
              <th className="rate-title">ID</th>
              <th className="rate-title">{t("Topic Title")}</th>
              <th className="rate-title">{t("Author")}</th>
              <th className="rate-title">{t("Subject")}</th>
              <th
                className="rate-title sort"
                onClick={() => onColumnHeaderClick("class")}
              >
                {t("Grade")}
                <img src={triangleIcon} alt="" className="sort-icon" />
              </th>
              <th
                onClick={() => onColumnHeaderClick("views")}
                className="rate-title sort"
              >
                {t("Views")}
                <img src={triangleIcon} alt="" className="sort-icon" />
              </th>

              <th
                onClick={() => onColumnHeaderClick("rate")}
                className="rate-title sort"
              >
                {t("Rating")}
                <img src={triangleIcon} alt="" className="sort-icon" />
              </th>
              <th className="rate-title">{t("Status")}</th>
            </tr>
            <tbody>
              {ratingData[0]?.map((el) => (
                <TableContent allUsers={ratingData} key={el.id} el={el} />
              ))}
            </tbody>
          </table>
        )}
      </div>
      {!ratingError && ratingData && <Pagination pageCount={pageCount || 10} />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    ratingData: state.studentsReducer.ratingData,
    ratingError: state.studentsReducer.ratingError,
    ratingSearch: state.formReducer.ratingSearch,
    ratingClass: state.formReducer.ratingClass,
    ratingSubject: state.formReducer.ratingSubject,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRatingData: (
      page,
      count,
      column,
      isOrderAsc,
      search,
      ratingClass,
      ratingSubject
    ) =>
      dispatch(
        getRatingData(
          page,
          count,
          column,
          isOrderAsc,
          search,
          ratingClass,
          ratingSubject
        )
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableComponent);
