import { ShowImagesPopUp } from "../../../components";
import close from "../../../img/closebutton.svg";
import "../studentsLessonPlan.css";

export const MethodDetails = ({
  currentMethod,
  steps,
  handleCloseDetail,
  source,
  detailImagesourceFirst,
  detailImageSourceSec,
}) => {
  const srcArray = {
    files: [detailImagesourceFirst],
    urlPath: detailImageSourceSec,
    imageIndex: 0,
    itemNumber: 1,
  };
  const currentStep = currentMethod?.order + "" + "/" + steps;

  return (
    <ShowImagesPopUp
      currentStep={currentStep}
      images={srcArray}
      open={true}
      onClose={handleCloseDetail}
      hasImgaeTitle={false}
    >
      <div className="detail-container">
        <div className="close-content">
          <div className="alert-button">
            Քայլ {currentMethod.order}/{steps}
          </div>
          <img
            src={close}
            alt="close"
            className="close-rating"
            onClick={handleCloseDetail}
          />
        </div>
        <img src={source} className="detail-image" alt="img" />
      </div>
    </ShowImagesPopUp>
  );
};
