import axios from "axios";

export function disableLsnPlanItem({ lessonPlanId, statusId, isRemove }) {
  const body = {
    lessonPlanId,
    statusId,
    isRemove,
  };

  return () =>
    axios
      .put("/api/lessonPlan/metadata/hide", body)
      .then((res) =>  res.data.data)
      .catch((error) => error);
}
