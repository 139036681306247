import {
  GET_LESSON_PLAN_STUDENT_REQUEST,
  GET_LESSON_PLAN_STUDENT_SUCCESS,
  GET_LESSON_PLAN_STUDENT_FAILURE,
  GET_LESSON_PLAN_LINK_REQUEST,
  GET_LESSON_PLAN_LINK_SUCCESS,
  GET_LESSON_PLAN_LINK_FAILURE,
  GET_RATING_DATA_REQUEST,
  GET_RATING_DATA_SUCCESS,
  GET_RATING_DATA_FAILURE,
} from "./types";

const initialState = {
  isLoadingLink: false,
  error: null,
  lessonPlanLinkForStudent: "",
  lessonPlanData: {},
  lessonPlanError: null,
  isLoadingLessonPlanData: false,
  ratingData: {},
  isLoadingRatingData: false,
  ratingError: false,
};

const studentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LESSON_PLAN_LINK_REQUEST:
      return {
        ...state,
        isLoadingLink: action.payload.isLoadingLink,
      };
    case GET_LESSON_PLAN_LINK_SUCCESS:
      return {
        ...state,
        isLoadingLink: false,
        error: null,
        lessonPlanLinkForStudent: action.payload.lessonPlanLinkForStudent,
      };
    case GET_LESSON_PLAN_LINK_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        lessonPlanLinkForStudent: action.payload.lessonPlanLinkForStudent,
      };

    case GET_LESSON_PLAN_STUDENT_REQUEST:
      return {
        ...state,
        isLoadingLessonPlanData: action.payload.isLoadingLessonPlanData,
      };
    case GET_LESSON_PLAN_STUDENT_SUCCESS:
      return {
        ...state,
        isLoadingLessonPlanData: false,
        lessonPlanError: null,
        lessonPlanData: action.payload.lessonPlanData,
      };
    case GET_LESSON_PLAN_STUDENT_FAILURE:
      return {
        ...state,
        lessonPlanError: action.payload.lessonPlanError,
        lessonPlanData: action.payload.lessonPlanData,
      };

    case GET_RATING_DATA_REQUEST:
      return {
        ...state,
        isLoadingRatingData: true,
      };
    case GET_RATING_DATA_SUCCESS:
      return {
        ...state,
        isLoadingRatingData: false,
        ratingData: action.payload.ratingData,
      };
    case GET_RATING_DATA_FAILURE:
      return {
        ...state,
        ratingError: true,
        isLoadingRatingData: false,
      };
    default:
      return state;
  }
};

export default studentsReducer;
