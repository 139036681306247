import HeaderAndLogo from "../../components/LoginRegister/HeaderAndLogo";

export function LoginAndRegisterlayout({ component }) {
  return (
    <>
      <HeaderAndLogo />
      {component}
    </>
  );
}
